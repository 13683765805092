body {
    margin-bottom: 6em;
}

.treeview .btn-default {
    border-color: #e3e5ef;
}

.treeview .btn-default:hover {
    background-color: #f7faea;
    color: #bada55;
}

.treeview ul {
    list-style: none;
    padding-left: 32px;
}

.treeview ul li {
    padding: 50px 0px 0px 35px;
    position: relative;
}

.treeview ul li:before {
    content: "";
    position: absolute;
    top: -26px;
    left: -31px;
    border-left: 2px dashed #a2a5b5;
    width: 1px;
    height: 100%;
}

.treeview ul li:after {
    content: "";
    position: absolute;
    border-top: 2px dashed #a2a5b5;
    top: 70px;
    left: -30px;
    width: 65px;
}

.treeview ul li:last-child:before {
    top: -22px;
    height: 90px;
}

.treeview > ul > li:before {
    content: unset;
}

.treeview > ul > li:after,
.treeview > ul > li:last-child:before {
    content: unset;
}

.treeview > ul > li:before {
    top: 90px;
    left: 36px;
}

.treeview > ul > li:not(:last-child) > ul > li:before {
    content: unset;
}


.treeview > ul > li > ul {
    padding-left: 34px;
}

.treeview__level {
    padding: 7px;
    padding-left: 42.5px;
    display: inline-block;
    border-radius: 5px;
    font-weight: 700;
    border: 1px solid #e3e5ef;
    position: relative;
    z-index: 1;
    transition: transform 0.3s ease;
}
.treeview__level:hover{
    transform: scale(1.1);
    background-color: #000;
}


.avtar{
    content: "";
    position: absolute;
    left: -30px;
    top: -6.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: var(--image-url);
    height: 55px;
    width: 55px;
    border-radius: 50%;
    border: 7.5px solid #eef6d5;
    background-color: #bada55;
    color: #fff;
    font-size: 20px; 
    overflow: hidden;
}


.treeview__level:before {
    content: ""; /* Remove content attribute */
    position: absolute;
    left: -30px;
    top: -6.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background-size: cover; /* Adjust image size */
    border: 7.5px solid #eef6d5;
    color: #fff;
    font-size: 20px;
}

.treeview__level-btns {
    margin-left: 15px;
    display: inline-block;
    position: relative;
}

.level-same,
.level-sub {
    position: absolute;
    display: none;
    transition: opacity 250ms cubic-bezier(0.7, 0, 0.3, 1);
}

.level-same.in,
.level-sub.in {
    display: block;
}

.level-same {
    top: 0;
    left: 45px;
}

.level-sub {
    top: 42px;
    left: 0px;
}

.level-remove {
    display: none;
}

.treeview__level.selected {
    background-color: #f9f9fb;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.10);
}

.treeview__level.selected .level-remove {
    display: inline-block;
}

.treeview__level.selected .level-add {
    display: none;
}

.treeview__level.selected .level-same,
.treeview__level.selected .level-sub {
    display: none;
}

.level-title {
    cursor: pointer;
    user-select: none;
}

.level-title:hover {
    text-decoration: underline;
}

.treeview--mapview ul {
    justify-content: center;
    display: flex;
}

.treeview--mapview ul li:before,
.treeview--mapview ul li:after {
    content: unset;
}
